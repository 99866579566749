import Swal from "sweetalert2";

export enum LayoutType {
    horizontal = "horizontal",
    vertical = "vertical"
}

export enum LangType {
    fr = "fr",
    en = "en"
}

export enum FormActionType {
    ADD = "add",
    EDIT = "edit",
    DETAIL = "detail"
}

export const swal = Swal.mixin({
    customClass: {
        confirmButton: 'mr-3 p-4 bg-soft-primary rounded',
        cancelButton: 'ml-2 p-4 bg-soft-danger rounded'
    },
    buttonsStyling: false
})

export const FIRST_PAGE_VALUE = 1;
export const PER_PAGE_VALUE = 25;
export const NUMBERS_OF_ROWS_LOADER = 5;
export const HOME_PAGE = '/message';
export const SSO_ENABLE = false;

export enum MESSAGE_STATUS_ENUM {
    SENT = "SENT",
    CREATED = "CREATED",
    DELIVRD = "DELIVRD",
    UNDELIV = "UNDELIV",
    REJECTED = "REJECTED",
    FAILED = "FAILED"
}

export const MESSAGE_STATUS = [
    {
        id: "1",
        name: MESSAGE_STATUS_ENUM.SENT,
        color: "#9DACF1FF"
    },
    {
        id: "2",
        name: MESSAGE_STATUS_ENUM.CREATED,
        color: "#ef9afc"
    }, {
        id: "3",
        name: MESSAGE_STATUS_ENUM.DELIVRD,
        color: "#91fab0"

    }, {
        id: "4",
        name: MESSAGE_STATUS_ENUM.UNDELIV,
        color: "#fa9391"

    },
    {
        id: "5",
        name: MESSAGE_STATUS_ENUM.REJECTED,
        color: "#fa9bed"

    },
    {
        id: "6",
        name: MESSAGE_STATUS_ENUM.FAILED,
        color: "#f7de9e"

    }
];