import SimpleButton from "./SimpleButton";
import MDIcons from "../icons/MDIcons";
import BoxIcons from "../icons/BoxIcons";
import { ButtonPropsInterface } from "./typings";
import { useTranslation } from "react-i18next";
import { MessageOutlined, SearchOutlined } from "@ant-design/icons";


export function AddButton({ value = null, onClick }: ButtonPropsInterface) {
    const { t } = useTranslation();
    value = value === null ? t('button:create') : value;
    return (<SimpleButton type={"button"} className={"primary rounded flex items-center"}
        onClick={onClick}><MDIcons icon={"plus"} className={value ? "mr-2" : ""} /> {value}
    </SimpleButton>);
}

export function SubmitButton({ value = 'Enregistrer', className = "", loading = false, onClick }: ButtonPropsInterface) {
    return <SimpleButton onClick={onClick} className={`primary rounded flex items-center justify-center ${className}`}
        type="submit" disabled={loading === true && 'disabled'}>
        {loading === true &&
            <BoxIcons icon={"loader"} className={"animate-spin font-size-16 align-middle mr-2"} />} {value}
    </SimpleButton>
}

export function SubmitMsgButton({ value = 'Enregistrer', className = "", loading = false, onClick }: ButtonPropsInterface) {
    return <SimpleButton onClick={onClick} className={` bg-blue-700 hover:bg-blue-400 text-lg text-white rounded flex items-center justify-center ${className}`}
        type="submit" disabled={loading === true && 'disabled'}>
        {loading === true &&
            <BoxIcons icon={"loader"} className={"animate-spin font-size-16 align-middle mr-2"} />}
        <div className="flex items-center justify-center gap-x-2">
            <SearchOutlined style={{ fontSize: '20px', }} />{value}
        </div>
    </SimpleButton>
}

export function CancelButton({ value = 'Annuler', onClick }: ButtonPropsInterface) {
    return (<SimpleButton type={"reset"} className={"secondary rounded"} onClick={onClick}> {value} </SimpleButton>);
}

export function EditButton({ value = 'Modifier', onClick, className = "" }: ButtonPropsInterface) {
    return (<SimpleButton className={`bg-primary rounded bg-opacity-50 hover:bg-opacity-100 flex items-center ${className}`}
        onClick={onClick}><MDIcons icon={"pencil"} outline={true} className={value ? "mr-2" : ""} /> {value}
    </SimpleButton>);
}

export function DeleteButton({ value = 'Supprimer', loading = false, onClick }: ButtonPropsInterface) {
    return (<SimpleButton
        className="bg-red-500 bg-opacity-40 hover:bg-opacity-100 text-white rounded flex items-center justify-center"
        onClick={onClick}>{loading === true ?
            <BoxIcons icon={"loader"} className={"animate-spin font-size-16 align-middle me-2"} /> :
            <MDIcons icon={"delete"} outline={true} className={value ? "mr-2" : ""} />} {value} </SimpleButton>);
}

export function LinkButton({ children, onClick, disabled = false, active = false, className }: ButtonPropsInterface) {
    return (<SimpleButton
        className={`text-center text-sm font-normal transition-colors hover:bg-blue-500 hover:text-white rounded-lg ${className} ${active ? "primary cursor-not-allowed" : ""} ${disabled ? "cursor-not-allowed" : ""}`}
        onClick={onClick}> {children} </SimpleButton>);
}

export function FilterButton({ value = 'Filtrer', onClick }: ButtonPropsInterface) {
    return (<SimpleButton
        className="bg-soft-secondary bg-opacity-40 hover:bg-opacity-100 rounded flex items-center justify-center"
        onClick={onClick}> <BoxIcons icon={"search-alt-2 "} className={value ? "mr-2" : ""} /> {value} </SimpleButton>);
}
export function DetailButton({ value = 'Detail', onClick }: ButtonPropsInterface) {
    return (<SimpleButton
        className="bg-soft-secondary bg-opacity-40 hover:bg-opacity-100 rounded flex items-center justify-center"
        onClick={onClick}> <BoxIcons icon={"show"} className={value ? "mr-2" : ""} /> {value} </SimpleButton>);
}
export function CloseButton({ onClick }: ButtonPropsInterface) {
    return (<SimpleButton
        className="p-1 ml-auto bg-transparent border-0 text-black text-3xl leading-none font-semibold outline-none focus:outline-none flex items-center justify-center"
        onClick={onClick}>
        <span className="bg-transparent text-red-500 h-6 w-6 text-2xl block">
            <BoxIcons icon={"x"} />
        </span> </SimpleButton>);
}