import { FC, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import CardTable from "../../components/card/CardTable";
import { InputText, TextArea } from "../../components/form/Input";
import ModalForm from "../../components/modal/ModalForm";
import { FIRST_PAGE_VALUE, MESSAGE_STATUS, PER_PAGE_VALUE } from "../../config/global";
import { APP_API_URL } from "../../utils/api";
import { useMutateWithInvalidateQueries } from "../../utils/mutation";
import { useQueries } from "../../utils/query";
import moment from "moment";
import SimpleButton from "../../components/button/SimpleButton";
import { Select2, Select2Tag } from "../../components/form/Select";
import { useAuth } from "../../hooks/useAuth";
import { TRAFFICS } from "./configDatas";
import { CheckRole, UserRoles } from "../../config/roles";
import { ColumnInterface } from "../../components/table/typings";
import { BadgeCircleCheck } from "../../components/badge/Badge";
import Card from "../../components/card/Card";
import { SubmitButton, SubmitMsgButton } from "../../components/button/CustomButton";
import Datepicker from "react-tailwindcss-datepicker";
import { formatDateWithTime } from "../../utils/helpers";
import { MessageOutlined } from "@ant-design/icons";

type MessageStatusComponentProps = {
    status: string
}

export const MessageStatusComponent: FC<MessageStatusComponentProps> = ({ status }) => {
    const statusColor = MESSAGE_STATUS.find(messageStatus => messageStatus.name === status);
    return (
        <>
            <span style={{ backgroundColor: statusColor?.color, color: "black" }} className={` px-2 py-1 text-[10px] rounded-md ${statusColor?.color}`}>{status}</span>
        </>
    )
}

export default function MessagePage() {
    const { user: auth } = useAuth();
    const isAdmin = CheckRole([UserRoles.ADMIN]);
    const isSupport = CheckRole([UserRoles.SUPPORT]);
    // let queryKey = ['messages', pagination.page, pagination.perPage];
    const [openModal, setOpenModal] = useState(false);

    //Columns Table
    const columns: ColumnInterface[] = [
        {
            title: "#",
            key: "index",
            render: (element, index) => <>{index + 1}</>,
        },
        {
            title: "Téléphone",
            key: "phone",
            render: (element: any) => <span>{element.msisdn}</span>,
        },
        {
            title: "Opérateur",
            key: "operator",
            render: (element: any) => <span>{element.operatorName}</span>,
        },
        {
            title: "Sender",
            key: "sender",
            render: (element: any) => <span>{element.sender}</span>,
        },
        {
            title: "Message",
            dataIndex: "message",
            key: "message",
        },
        {
            title: "Crédit",
            key: "creadit",
            dataIndex: "smsUnit",
        },
        {
            title: "Status",
            key: "status",
            render: (element: any) => <MessageStatusComponent status={element.status} />,
        },
        {
            title: "DLR Sent",
            key: "dlrSent",
            render: (element: any) => (<BadgeCircleCheck active={element.dlrSent} />),
        },
        {
            title: "Date de création",
            key: "createdAt",
            render: (element: any) => <span>{element.createdAt ? moment(element.createdAt).format('YYYY-MM-DD HH:mm:ss') : "----"}</span>,
        },
        {
            title: "Date d'envoi",
            key: "submitTime",
            render: (element: any) => <span>{element.submitTime ? moment(element.submitTime).format('YYYY-MM-DD HH:mm:ss') : "----"}</span>,
        },
        {
            title: "Date de livraison",
            key: "deliveryTime",
            render: (element: any) => <span>{element.deliveryTime ? moment(element.deliveryTime).format('YYYY-MM-DD HH:mm:ss') : "----"}</span>,
        },
        {
            title: "SMS ID",
            key: "id",
            render: (element: any) => <span>{element.id}</span>,
            isVisible: isAdmin
        },
        {
            title: "Service",
            key: "provider",
            render: (element: any) => <span>{element.serviceName}</span>,
            isVisible: isAdmin
        },
        {
            title: "Utilisateur",
            key: "username",
            render: (element: any) => <span>{element.userName}</span>,
            isVisible: isAdmin || isSupport
        },
        {
            title: "IP",
            key: "userIp",
            dataIndex: "userIp",
            isVisible: isAdmin || isSupport

        },
        {
            title: "Mode",
            key: "smsMode",
            render: (element: any) => (<span className={`badge ${element.smsMode === "SMPP" ? "badge-soft-success" : "badge-soft-warning"}`}>{element.smsMode}</span>),
            isVisible: isAdmin || isSupport

        },
        {
            title: "Provider ID",
            key: "providerMsgId",
            dataIndex: "providerMsgId",
            isVisible: isAdmin
        },


    ];
    const [pagination, setPagination] = useState({ page: FIRST_PAGE_VALUE, perPage: PER_PAGE_VALUE });
    const [filterDateRange, setFilterDateRange] = useState<any>({ startDate: new Date(), endDate: new Date() });

    const [filter, setFilter] = useState<any>({
        phone: '',
        operatorId: 0,
        status: '',
        startDate: formatDateWithTime(filterDateRange?.startDate, "00:00:00"),
        endDate: formatDateWithTime(filterDateRange?.endDate, "23:59:59"),
        sender: "",
        userId: 0
    });


    let queryKey = ['messages', pagination.page, pagination.perPage, filter?.userId, filter.phone, filter.operatorId, filter.status, filter?.startDate, filter?.endDate, filter?.sender];

    const {
        register, reset, control, handleSubmit, formState: {
            errors
        }
    } = useForm();

    const {
        register: filterMessageRegister, control: filterMessageControl, handleSubmit: filterMessagehandleSubmit, formState: {
            errors: filterMessageErrors
        }
    } = useForm();

    const {
        data: messages,
        refetch: refetchMessages,
        isLoading
    } = useQueries(APP_API_URL + `filter-messages?pageNo=${pagination.page}&pageSize=${pagination.perPage}&phone=${filter?.phone}&operatorId=${filter?.operatorId}&status=${filter?.status}&fromDate=${filter?.startDate}&toDate=${filter?.endDate}&sender=${filter?.sender}&userId=${filter?.userId}`, queryKey, {
        enabled: true,
        refetchInterval: 5000,

    });

    const {
        refetch: refetchService,
    } = useQueries(APP_API_URL + `service-list`, ['services-list'], {
        enabled: false
    });

    const {
        data: users,
        isLoading: usersLoading,
    } = useQueries(APP_API_URL + `user-list`, ['user-list'], {
        enabled: auth.roles.name === (UserRoles.ADMIN || UserRoles.ADMIN)
    });



    const {
        data: operators,
        isLoading: operatorsLoading,
    } = useQueries(APP_API_URL + `operator-list`, ['operator-list'], {
        enabled: true
    });

    const {
        mutateAsync: sendSMSData,
        isLoading: storeDataIsLoading
    } = useMutateWithInvalidateQueries(APP_API_URL + `send`, 'post', queryKey)

    //Functions
    const handleSubmitSendSMSForm = (values: any) => {
        let mobilesNumbers = values.mobileno.map((mobile: any) => { return mobile.value }).toString()
        // sendSMSData({ ...values, mobileno: mobilesNumbers, serviceId: values?.serviceId?.id ?? null, traffic: values.traffic.value, apiKey: auth.user.api_key }).then((response: any) => {
        //     if (response.status === 200) {
        //         reset();
        //         setOpenModal(false);
        //         toast.success('Message envoyé');
        //     }
        // });
        sendSMSData({ ...values, mobileno: mobilesNumbers }).then((response: any) => {
            console.log(response.data)
            if (response?.data?.status === "ERROR") {
                toast.error(response?.data?.message);
            } else if (response?.data?.status === "SENT") {
                reset();
                setOpenModal(false);
                toast.success('Message envoyé');
            }

            // if (response.status === 200) {
            //     reset();
            //     setOpenModal(false);
            //     toast.success('Message envoyé');
            // } else {
            //     toast.error("RRRRRR");
            // }
        });
        // console.log({ ...values })
    };

    const handleCancelForm = () => {
        reset();
        setOpenModal(false);
    };

    const handleSendSMS = (canGetServices?: boolean) => {
        setOpenModal(true);
        reset();
        if (canGetServices) {
            refetchService();
        }
        // setValue('traffic', TRAFFICS[0])
    }

    const handleSetDateRange = (newDateRange: any) => {
        console.log(newDateRange);
        setFilterDateRange(newDateRange)

    }

    const handlerFilterMessages = (values: any) => {
        // console.log("filter user : ", values?.filterUserId?.id);
        // console.log("filter operator : ", values?.operatorId?.id);
        // console.log("filter sender : ", values?.sender);
        // console.log("filter phone : ", values?.filterPhone);
        // console.log("filter status : ", values?.filterStatus?.name);
        // console.log("filter date from: ", formatDateWithTime(filterDateRange?.startDate ?? new Date(), "00:00:00"));
        // console.log("filter date to: ", formatDateWithTime(filterDateRange?.endDate ?? new Date(), "23:59:59"));

        // set state data
        setFilter({
            userId: values?.filterUserId?.id ?? 0,
            operatorId: values?.operatorId?.id ?? 0,
            sender: values?.sender ?? '',
            phone: values?.filterPhone ?? '',
            status: values?.filterStatus?.name ?? '',
            startDate: formatDateWithTime(filterDateRange?.startDate, "00:00:00"),
            endDate: formatDateWithTime(filterDateRange?.endDate, "23:59:59")

        });

        // refetch data with updated filter params
        refetchMessages();
    }

    // useEffect(() => {
    //     if (CheckRole([UserRoles.ADMIN])) {
    //         refetchUsers()
    //     }
    // })


    return (
        <>
            <div className="card card-header">
                <div className="card-title">Filtre </div>
                <div className="card-body " >
                    <div className="flex items-center justify-between gap-x-2" >
                        <div className="flex items-center gap-x-3 flex-grow ">
                            {CheckRole([UserRoles.ADMIN]) && (
                                <div className="w-1/5" >
                                    <Select2
                                        isLoading={usersLoading}
                                        control={filterMessageControl}
                                        placeholder={"Utilisateur"}
                                        label={"Utilisateur"}
                                        register={filterMessageRegister}
                                        error={filterMessageErrors?.filterUserId}
                                        name={"filterUserId"}
                                        textKeyItem={"username"}
                                        rules={{ required: false }}
                                        valueKeyItem={"id"}
                                        items={users?.data?.data} />
                                </div>
                            )}

                            <div className="w-1/5" >
                                <Select2
                                    isLoading={operatorsLoading}
                                    control={filterMessageControl}
                                    placeholder={"Operateur"}
                                    label={"Operateur"}
                                    register={filterMessageRegister}
                                    error={filterMessageErrors?.operatorId}
                                    name={"operatorId"}
                                    textKeyItem={"name"}
                                    valueKeyItem={"id"}
                                    rules={{ required: false }}

                                    items={operators?.data?.data} />
                            </div>
                            <div className="w-1/5" >
                                <InputText
                                    control={filterMessageControl}
                                    label="Sender"
                                    options={{ required: false }}
                                    placeholder={"Entrer le sender"}
                                    register={filterMessageRegister}
                                    error={filterMessageErrors?.sender}
                                    name={"sender"} />

                            </div>
                            <div className="w-1/5" >
                                <InputText
                                    options={{ required: false }}

                                    control={filterMessageControl}
                                    name={"filterPhone"}
                                    label={"Phone"}
                                    placeholder={"Entrer le numero de telephone"}
                                    register={filterMessageRegister}

                                    error={filterMessageErrors?.filterPhone}
                                />
                            </div>

                            <div className="w-1/5" >
                                <Select2
                                    control={filterMessageControl}
                                    placeholder={"Status"}
                                    label={"Status"}
                                    register={filterMessageRegister}
                                    error={filterMessageErrors?.filterStatus}
                                    name={"filterStatus"}
                                    textKeyItem={"name"}
                                    rules={{ required: false }}

                                    valueKeyItem={"name"}
                                    items={MESSAGE_STATUS} />
                            </div>

                            <div className="w-1/5 flex flex-col gap-y-2" >
                                <label htmlFor="daterange">Periode</label>
                                <Datepicker
                                    value={filterDateRange}
                                    onChange={newValue => handleSetDateRange(newValue)}
                                    showShortcuts={true}
                                />
                            </div>

                        </div>
                        <div className="pt-6">
                            <SubmitMsgButton onClick={filterMessagehandleSubmit(handlerFilterMessages)} value={"Rechercher"} />
                        </div>
                    </div>
                </div>
            </div>
            <CardTable extras={[
                (<SimpleButton className="btn bg-green-600 text-white rounded-full hover:bg-green-400 flex items-center justify-center gap-x-2  py-2" onClick={() => handleSendSMS(false)} key={"send"}><MessageOutlined style={{ fontSize: '20px', }} /><span>Envoyer un SMS</span></SimpleButton>)
            ]} columns={columns} title={"Messages"}
                loading={isLoading} datas={messages?.data}
                pagination={pagination} setPagination={setPagination}
                tableClassName="text-center table-striped" />
            <ModalForm title={"Envoyer un SMS"} isLoading={storeDataIsLoading}
                onClose={handleCancelForm}
                onConfirm={handleSubmit(handleSubmitSendSMSForm)}
                open={openModal}>
                <div className={"grid grid-cols-1 md:grid-cols-2 gap-4"}>
                    {/* {CheckRole([UserRoles.ADMIN]) && (
                        <div>
                            <Select2 control={control} placeholder={"Selectionner le service"} label={"Service"}
                                register={register} error={errors?.serviceId}
                                name={"serviceId"} textKeyItem={"name"} valueKeyItem={"id"} items={services?.data?.data} />
                        </div>
                    )} */}

                    {/* <div>
                        <Select2
                            control={control}
                            placeholder={"Selectionner le traffic"}
                            label={"Trafic"}
                            register={register}
                            error={errors?.traffic}
                            name={"traffic"}
                            textKeyItem={"label"}
                            valueKeyItem={"value"}
                            items={TRAFFICS} />
                    </div> */}

                    <div>
                        <InputText placeholder={"Entrer le sender"} label={"Sender"} register={register}
                            error={errors?.sender}
                            name={"sender"} />
                    </div>
                    <div className="col-span-2">
                        <Select2Tag placeholder={"Entrer numero de telephone"} label={"Numeros de telephones"}
                            register={register}
                            error={errors?.mobileno}
                            multiple
                            control={control}
                            name={"mobileno"} textKeyItem={"label"} valueKeyItem={"value"} />
                    </div>
                    <div className="col-span-2">
                        <TextArea placeholder={"Entrer votre message"} label={"Message"}
                            register={register}
                            error={errors?.message}
                            name={"message"} />
                    </div>
                </div>
            </ModalForm>
        </>
    );
}
